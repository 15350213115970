
import MyForm from "../components/forms-map";



function ContactPage() {
    return (
        <div>
            <MyForm></MyForm>
        </div>
    );
}

export default ContactPage;




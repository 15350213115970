


import React, { useEffect, useState } from "react";
import { firestore } from '../firebase';
import { collection, getDocs, doc, setDoc, addDoc, deleteDoc, orderBy, query } from "firebase/firestore";

function AddComment() {

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [comment, setComment] = useState('');
    const [reviews, setReviews] = useState([]);
    const [order, setOrder] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        getReview();
    }, []);


    const addReview = async () => {
        try {
            const docRef = await addDoc(collection(firestore, "reviews"), {

                name,
                company,
                comment,
                order,
                image
            });
            console.log("Document successfully written with ID: ", docRef.id);
            alert("Review Successfully Added !");
            clearTexts();
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Review Adding Failed !");
        }
    }

    const deleteReview = async (reviewId) => {
        try {
            await deleteDoc(doc(firestore, "reviews", reviewId));
            console.log("Document successfully deleted!");
            alert("Review Deleted !");
            await getReview(); // Wait for the deletion to complete before fetching updated data
        } catch (error) {
            console.error("Error deleting document: ", error);
            alert("Delete Failed !");
        }
    }


    const updateReview = async (reviewId) => {
        try {
            const reviewRef = doc(firestore, "reviews", reviewId);
            await setDoc(reviewRef, {

                name,
                company,
                comment,
                order,
                image

            }, { merge: true });
            console.log("Document successfully updated!");
            alert("Review Successfully Updated !");
            clearTexts();
            // Optionally, you can refresh the reviews after update
            getReview();
        } catch (error) {
            console.error("Error updating document: ", error);
            alert("Review Update Failed !");
        }
    }


    const getReview = async () => {
        const q = query(collection(firestore, 'reviews'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const reviewsList = [];
        querySnapshot.forEach((doc) => {
            reviewsList.push({ id: doc.id, ...doc.data() });
        });
        setReviews(reviewsList);
    };


    const clearTexts = () => {

        setName('');
        setCompany('');
        setComment('');
        setOrder('');
    }

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedImage);
        } else {
            setImage(null);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div class="col-md-6 offset-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div className="col">

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Order</label>
                                    <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="01" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">
                                        Upload Image 100px x 100px 72 ppi
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>

                                {image && (
                                    <div className="mb-3">
                                        <label className="form-label">Image Preview</label>
                                        <img src={image} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                    <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} class="form-control" id="exampleFormControlInput1" placeholder="Avery Parker" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Company and Designation</label>
                                    <input type="text" value={company} onChange={(e) => { setCompany(e.target.value) }} class="form-control" id="exampleFormControlInput1" placeholder="Drafting Technician in Pulse Design Group" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Review</label>
                                    <textarea value={comment} onChange={(e) => { setComment(e.target.value) }} class="form-control" id="message" rows="4" placeholder='"Designing ..... it serves."'></textarea>
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={addReview}>
                                    Submit
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Order</th>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col">Company and Designation</th>
                                <th scope="col">Review</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviews.map(reviews => (
                                <tr key={reviews.id}>

                                    <td>{reviews.order}</td>
                                    <td>{reviews.image && <img src={reviews.image} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                                    <td>{reviews.name}</td>
                                    <td>{reviews.company}</td>
                                    <td>{reviews.comment}</td>
                                    <td>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => updateReview(reviews.id)}
                                        >
                                            Update
                                        </button>

                                        <button
                                            className="btn btn-primary m-1"
                                            onClick={() => {
                                                setOrder(reviews.order);
                                                setImage(reviews.image || null);
                                                setName(reviews.name);
                                                setCompany(reviews.company);
                                                setComment(reviews.comment);

                                            }}
                                        >
                                            Load
                                        </button>

                                        <button type="button" class="btn btn-danger " onClick={() => deleteReview(reviews.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AddComment;

import React, { useEffect, useState } from "react";
import { firestore } from '../firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";

function ReviewText() {

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        getReview();
    }, []);

    const getReview = async () => {
        const q = query(collection(firestore, 'reviews'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const reviewsList = [];
        querySnapshot.forEach((doc) => {
            reviewsList.push({ id: doc.id, ...doc.data() });
        });
        setReviews(reviewsList);
    };


    return (
        <div className="container ">
            <h3 className="mb-12 text-center foothead " >CUSTOMER REVIEWS</h3>
            <div id="carouselExampleDark1" className="carousel carousel-dark slide" data-bs-ride="carousel" style={{ marginBottom: "2%", paddingBottom: "4%", paddingTop: "0%", marginTop: "0%" }}>
                <div className="carousel-inner">
                    {reviews.map((review, index) => (
                        <div key={review.id} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-ride="carousel" data-bs-interval="5000">
                            <div className="card-body d-flex justify-content-center" >
                                <div className="col-md-12 rev-img">
                                    <a target="_blank" rel="noreferrer" href="https://www.fiverr.com/mgassoori">
                                        <img src={review.image} className="w-100" alt="" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                    </a>
                                </div>
                            </div>
                            <a style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" href="https://www.fiverr.com/mgassoori">
                                <blockquote className="des text-center">
                                    <h6>{review.comment}</h6>
                                </blockquote>
                            </a>
                            <footer className="blockquote-footer">
                                {review.name} <br />
                                <cite title="Source Title">{review.company}</cite>
                            </footer>
                        </div>

                    ))}
                </div>

                <button className="carousel-control-prev d-none d-md-block" type="button" data-bs-target="#carouselExampleDark1" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" style={{ left: "-80px", position: "absolute", top: "40%", height: "20px" }}></span>
                    <span className="visually-hidden">Previous</span>
                </button>

                <button className="carousel-control-next d-none d-md-block" type="button" data-bs-target="#carouselExampleDark1" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" style={{ right: "-80px", position: "absolute", top: "40%", height: "20px" }}></span>
                    <span className="visually-hidden">Next</span>
                </button>


            </div>
        </div >
    );
}

export default ReviewText;






import React, { useEffect, useState } from "react";
import { firestore } from '../firebase';
import { collection, getDocs, doc, setDoc, addDoc, deleteDoc, orderBy, query } from "firebase/firestore";

function AddHomeCarousel() {

    const [order, setOrder] = useState('');
    const [head, setHead] = useState('');
    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');
    const [carousel, setCarousel] = useState([]);



    useEffect(() => {
        getCarousel();
    }, []);


    const addCarousel = async () => {
        try {
            const docRef = await addDoc(collection(firestore, "carousel"), {

                order,
                head,
                image,
                description
            });
            console.log("Document successfully written with ID: ", docRef.id);
            alert("Carousel Successfully Added !");
            clearTexts();
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Carousel Adding Failed !");
        }
    }

    const deleteCarousel = async (carouselId) => {
        try {
            await deleteDoc(doc(firestore, "carousel", carouselId));
            console.log("Document successfully deleted!");
            alert("Carousel Deleted !");
            await getCarousel(); // Wait for the deletion to complete before fetching updated data
        } catch (error) {
            console.error("Error deleting document: ", error);
            alert("Carousel Failed !");
        }
    }


    const updateCarousel = async (carouselId) => {
        try {
            const carouselRef = doc(firestore, "carousel", carouselId);
            await setDoc(carouselRef, {

                order,
                head,
                image,
                description

            }, { merge: true });
            console.log("Document successfully updated!");
            alert("Carousel Successfully Updated !");
            clearTexts();
            // Optionally, you can refresh the reviews after update
            getCarousel();
        } catch (error) {
            console.error("Error updating document: ", error);
            alert("Carousel Update Failed !");
        }
    }


    const getCarousel = async () => {
        const q = query(collection(firestore, 'carousel'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const carouselList = [];
        querySnapshot.forEach((doc) => {
            carouselList.push({ id: doc.id, ...doc.data() });
        });
        setCarousel(carouselList);
    };





    const clearTexts = () => {

        setOrder('')
        setHead('');
        setDescription('');
        setImage('');

    }

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedImage);
        } else {
            setImage(null);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div class="col-md-6 offset-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div className="col">

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Order</label>
                                    <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="01" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">
                                        Upload Image 1350px x 575px
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>

                                {image && (
                                    <div className="mb-3">
                                        <label className="form-label">Image Preview</label>
                                        <img src={image} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Head</label>
                                    <input type="text" value={head} onChange={(e) => { setHead(e.target.value) }} class="form-control" id="exampleFormControlInput1" placeholder="Project Name" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Description</label>
                                    <input type="text" value={description} onChange={(e) => { setDescription(e.target.value) }} class="form-control" id="exampleFormControlInput1" placeholder="Project Description" />
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={addCarousel}>
                                    Submit
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Order</th>
                                <th scope="col">Image</th>
                                <th scope="col">Head</th>
                                <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {carousel.map(carousel => (
                                <tr key={carousel.id}>

                                    <td>{carousel.order}</td>
                                    <td>{carousel.image && <img src={carousel.image} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                                    <td>{carousel.head}</td>
                                    <td>{carousel.description}</td>
                                    <td>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => updateCarousel(carousel.id)}
                                        >
                                            Update
                                        </button>

                                        <button
                                            className="btn btn-primary m-1"
                                            onClick={() => {
                                                setOrder(carousel.order);
                                                setImage(carousel.image || null);
                                                setHead(carousel.head);
                                                setDescription(carousel.description);

                                            }}
                                        >
                                            Load
                                        </button>

                                        <button type="button" class="btn btn-danger " onClick={() => deleteCarousel(carousel.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AddHomeCarousel;

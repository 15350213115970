import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, doc, setDoc, addDoc, orderBy, query, deleteDoc } from "firebase/firestore";

const AddService = () => {

    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [description1, setDescriptionPara1] = useState('');
    const [description2, setDescriptionPara2] = useState('');
    const [head, setHead] = useState('');
    const [services, setServices] = useState([]);
    const [order, setOrder] = useState('');


    useEffect(() => {
        getServices();
    }, []);

    const addService = async () => {
        try {
            const docRef = await addDoc(collection(firestore, "services"), {

                image,
                image2,
                head,
                description1,
                description2,
                order

            });
            console.log("Document successfully written with ID: ", docRef.id);
            alert("Service Successfully Added !");
            clearTexts();
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Service Adding Failed !");
        }
    }

    const deleteService = async (serviceId) => {
        try {
            await deleteDoc(doc(firestore, "services", serviceId));
            console.log("Document successfully deleted!");
            alert("Service Deleted !");
            await getServices(); // Wait for the deletion to complete before fetching updated data
        } catch (error) {
            console.error("Error deleting document: ", error);
            alert("Delete Failed !");
        }
    }



    const updateService = async (serviceId) => {
        try {
            const serviceRef = doc(firestore, "services", serviceId);
            await setDoc(serviceRef, {

                order,
                image,
                image2,
                head,
                description1,
                description2

            }, { merge: true });
            console.log("Document successfully updated!");
            alert("Service Successfully Updated !");
            clearTexts();
            // Optionally, you can refresh the reviews after update
            getServices();
        } catch (error) {
            console.error("Error updating document: ", error);
            alert("Service Update Failed !");
        }
    }



    const getServices = async () => {
        const q = query(collection(firestore, 'services'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const serviceList = [];
        querySnapshot.forEach((doc) => {
            serviceList.push({ id: doc.id, ...doc.data() });
        });
        setServices(serviceList);
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(selectedImage);
        } else {
            setImage(null);
        }
    };

    const handleImage2Change = (event) => {
        const selectedImage2 = event.target.files[0];
        if (selectedImage2) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage2(reader.result);
            };
            reader.readAsDataURL(selectedImage2);
        } else {
            setImage2(null);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here (e.g., sending data to the server)
        console.log('Form submitted:', { image, image2, description1, description2, head, order });
    };

    const clearTexts = () => {

        setImage('');
        setImage2('');
        setHead('');
        setDescriptionPara1('');
        setDescriptionPara2('');
        setOrder('')
    }

    return (

        <div className='container '>
            <div className="container mt-5">
                <div class="col-md-6 offset-md-3">
                    <div class="card">
                        <div class="card-body">


                            <form onSubmit={handleSubmit}>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Order</label>
                                    <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="01" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">
                                        Upload Image 1200px x 630px 72ppi
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>

                                {image && (
                                    <div className="mb-3">
                                        <label className="form-label">Image Preview</label>
                                        <img src={image} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label htmlFor="image2" className="form-label">
                                        Round Image for Home 200px x 200px 72 ppi
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image2"
                                        accept="image2/*"
                                        onChange={handleImage2Change}
                                    />
                                </div>

                                {image2 && (
                                    <div className="mb-3">
                                        <label className="form-label">Round Image Preview</label>
                                        <img src={image2} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}



                                <div className="mb-3">
                                    <label htmlFor="head" className="form-label">
                                        Service Head
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="head"
                                        value={head}
                                        onChange={(e) => setHead(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="description1" className="form-label">
                                        Description Para1
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="description1"
                                        value={description1}
                                        onChange={(e) => setDescriptionPara1(e.target.value)}
                                        rows="4"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description2" className="form-label">
                                        Description Para2
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="description2"
                                        value={description2}
                                        onChange={(e) => setDescriptionPara2(e.target.value)}
                                        rows="4"
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={addService}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Order</th>
                                <th scope="col">Round Image</th>
                                <th scope="col">Image</th>
                                <th scope="col">Head</th>
                                <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map(services => (
                                <tr key={services.id}>

                                    <td>{services.order}</td>
                                    <td>{services.image2 && <img src={services.image2} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                                    <td>{services.image && <img src={services.image} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                                    <td>{services.head}</td>
                                    <td>{services.description1} <br /><br />{services.description1}</td>

                                    <td className='text-center'>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => updateService(services.id)}
                                        >
                                            Update
                                        </button>

                                        <button
                                            className="btn btn-primary m-1"
                                            onClick={() => {

                                                setOrder(services.order)
                                                setImage(services.image);
                                                setImage2(services.image2);
                                                setHead(services.head);
                                                setDescriptionPara1(services.description1);
                                                setDescriptionPara2(services.description2);

                                            }}
                                        >
                                            Load
                                        </button>

                                        <button type="button" class="btn btn-danger " onClick={() => deleteService(services.id)}>Delete</button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    );
};

export default AddService;

import { useEffect, useState } from "react";
import { firestore } from "../firebase";
import { getDocs, orderBy, query, collection } from "firebase/firestore";
import { Link } from "react-router-dom";


function HomeServices() {

    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices();
    }, []);

    const getServices = async () => {
        const q = query(collection(firestore, 'services'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const serviceList = [];
        querySnapshot.forEach((doc) => {
            serviceList.push({ id: doc.id, ...doc.data() });
        });
        setServices(serviceList);
    };


    return (
        <div className="container mt-5" >
            <div className="row d-flex justify-content-center" >

                {services.map(service => (
                    <Link to={`/services#${service.id}`} key={service.id} className="col-md-3 text-decoration-none" >
                    <div className=" ">
                        <div className="card mt-3 mb-2 ser-card" style={{ alignItems:"center"}}>
                            <div className="col-md-12 ser-img" >
                                <img src={service.image2} className="w-100" alt="" style={{  objectFit: 'contain', width: '100%', height: '100%' }} />
                            </div>

                            <div className="col-md-12">
                                <div className="">
                                    <h3>{service.head}</h3>
                                </div>
                            </div>
                        </div>

                    </div>
                    </Link>
                ))}
            </div>
        </div>


    );
}

export default HomeServices;





import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import "./assets/style.css" 
import { Routes, Route } from 'react-router-dom';
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
//import ProjectsPage from "./pages/projects";
import ServicesPage from './pages/services';


import NavBar from "./components/navbar";
import Footer from "./components/footer";
import WhatsApp from "./components/whatsapp"
import Routing from './pages/routs';




function App() {

  
  return (
    <div>
        <NavBar></NavBar>
        <Routes>
          <Route path="/" element={<HomePage/>} />
         
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/services" element={<ServicesPage/>} />
        </Routes>
        <Routing></Routing>
        <WhatsApp></WhatsApp>
        <Footer></Footer>
    </div>
  );
}

export default App;




import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import countries from './countries';
import ContactDesign from './contact-design';

function MyForm() {

  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_wxfl6oa', // Service ID from EmailJS slmbdesign4@gmail.com
        'template_z3vsqmu', // Template ID from EmailJS slmb@1234 or slmb1234
        e.target, // The form element
        'uD3szOKu_lzve3ZhB' // User ID from EmailJS
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setSuccessAlertVisible(true);
          setTimeout(() => {
            setSuccessAlertVisible(false);
            window.location.reload(); // Refresh the page after 1 second
          }, 5000);
        },
        (error) => {
          console.error('Email could not be sent:', error.text);
          setTimeout(() => {
            alert('Email could not be sent. Please try again later.');
            window.location.reload(); // Refresh the page after 1 second
          }, 5000);
        }
      );
  };


  const sendReview = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_f6zxt2h', // Service ID from EmailJS slmbdesign4@gmail.com
        'template_831llng', // Template ID from EmailJS slmb@1234 or slmb1234
        e.target, // The form element
        'uD3szOKu_lzve3ZhB' // User ID from EmailJS
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setSuccessAlertVisible(true);
          setTimeout(() => {
            setSuccessAlertVisible(false);
            window.location.reload(); // Refresh the page after 1 second
          }, 5000);
        },
        (error) => {
          console.error('Email could not be sent:', error.text);
          setTimeout(() => {
            alert('Email could not be sent. Please try again later.');
            window.location.reload(); // Refresh the page after 1 second
          }, 5000);
        }
      );
  };


  return (
    <div className="container foothead mt-4">
      <div className="row ">

        {/* Contact Design Firm */}
        <div className="row">

          <div className="col-md-6 ">
            <ContactDesign></ContactDesign>
          </div>

          {/* Map Design Firm */}
          <div className="col-md-6">
            <div class="container w-100 p-4">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15795.006204226333!2d80.3443682!3d8.227721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afcf185b5e903ff%3A0xb74b54e4e390f2a4!2sSLMB%20Design%20Solutions!5e0!3m2!1sen!2slk!4v1696568280442!5m2!1sen!2slk"
                style={{ border: 0, width: "100%", height: "65vh" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>


          <div className="col-md-6 ">
            <div className="contact-wrap w-100  p-4 form-padding">
              <h3 className="mb-4 ">DROP US A MESSAGE</h3>

              <form onSubmit={sendEmail}>
                <div className="row ">
                  {/* Form fields */}

                  <div className="col-md-6">

                    <div className="form-group">
                      <label className="label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div />
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <select className="form-control " id="country" name="country">
                        <option value="">Select your country</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country.toLowerCase().replace(/\s/g, '_')}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* ... Other form fields ... */}

                <div className='row'>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="label" htmlFor="email">Email Address</label>
                      <input type="email" className="form-control" name="email" id="email" placeholder="Email" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="telephone">Telephone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="telephone"
                        placeholder="Eg. +94701234567"
                        name="telephone"
                      // Additional attributes, event handlers, and state can be added as needed
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label" htmlFor="message">Message</label>
                    <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder="Message"></textarea>
                  </div>
                </div>


                <div className='row'>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Send Message"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#112D4E" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-8">
                    {successAlertVisible && (
                      <div className="alert alert-success " role="alert" style={{ height: "65%", lineHeight: "40%" }}>
                        Message sent successfully!
                      </div>
                    )}

                  </div>
                </div>
              </form>
            </div>
          </div >

          {/* Reviw */}
          <div className="col-md-6 ">
            <div className="contact-wrap w-100  p-4 form-padding">
              <h3 className="mb-4">ADD A REVIEW</h3>

              <form onSubmit={sendReview}>

                <div className="row">
                  {/* Form fields */}

                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label className="label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div />
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label" htmlFor="email">Email Address</label>
                      <input type="email" className="form-control" name="email" id="email" placeholder="Email" />
                    </div>
                  </div>
                </div>

                <div className='row '>
                  <div class="col-md-6 ">
                    <div className="form-group">
                      <label for="company" class="form-label">Company</label>
                      <input type="text" class="form-control" id="company" name="company" placeholder='"optional"' />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="designation" class="form-label">Designation</label>
                      <input type="text" class="form-control" id="designation" name="designation" placeholder='"optional"' />
                    </div>
                  </div>
                </div>



                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label" htmlFor="message">Review</label>
                    <textarea name="review" className="form-control" id="review" cols="30" rows="4" placeholder="Add a Review"></textarea>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Add Review"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#112D4E" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-9">
                    {successAlertVisible && (
                      <div className="alert alert-success text-center " role="alert" style={{ height: "65%", lineHeight: "40%" }}>
                        Successfully added a review!
                      </div>
                    )}
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div >



        {/**  Contact Sales Center 
        <div className="row">

          <div className="col-md-6 ">
            <ContactSales></ContactSales>
          </div>

          {/* Map Sales Center 
          <div className="col-md-6">
            <div class="container w-100 p-4">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15795.006204226333!2d80.3443682!3d8.227721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afcf185b5e903ff%3A0xb74b54e4e390f2a4!2sSLMB%20Design%20Solutions!5e0!3m2!1sen!2slk!4v1696568280442!5m2!1sen!2slk"
                style={{ border: 0, width: "100%", height: "55vh" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div >**/}
      </div >
    </div >
  );
}

export default MyForm;





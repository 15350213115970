import { Link } from "react-router-dom";
import AddComment from "../components/addcomment";
import AddService from "../components/addservice";
import AddHomeCarousel from "../components/addcarousel";




function AdminPage1() {
    return (
        <div class={"container"}>
            <h1 class={"text-center"}>Add Reviews</h1>
            <AddComment></AddComment>
            <h1 class={"text-center"}>Add Services</h1>
            <AddService></AddService>
            <h1 class={"text-center"}>Add Carousel</h1>
            <AddHomeCarousel></AddHomeCarousel>

            <div className="text-center">
            <Link to="/admin2" >
                <button type="button" className="btn btn-primary">
                    Next Page
                </button>
            </Link>
            </div>
        </div>
    );
}

export default AdminPage1;

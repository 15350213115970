import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import homeimage from "../assets/homeimage.webp"


const HomeIntro = () => {
    return (
        <section className="">
            <Container>
                <Row className="text-center text-lg-start align-items-center">
                    <Col xs={12} lg={5} className="">
                        <h1 className="pb-4 mt-4">
                            Welcome to SLMB Design Solutions
                        </h1>
                        <p style={{ fontSize: "18px",  lineHeight: '2'  }} className="my-2">
                            At SLMB Design Solutions, We specialize in providing top-notch mechanical engineering design and prototyping services. With a team of highly skilled engineers, we cater to clients across the globe, delivering innovative solutions tailored to your specific needs.
                        </p>
                        <Button
                            href="https://www.fiverr.com/mgassoori" target="_blank"
                            className="mt-4"
                            style={{ color: "#112D4E" }}
                            variant="outline-primary"
                        >
                            <h6 className="mt-1">ORDER ON FIVERR</h6>
                        </Button>
                    </Col>
                    <Col xs={12} lg={7}>
                        <div className="position-relative mt-4 mt-md-0">
                            <img
                                src={homeimage}
                                className=" img-fluid"
                                alt=""
                                width="100%"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};


export default HomeIntro;
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/login';
import AdminPage1 from '../pages/admin1';
import AdminPage2 from '../pages/admin2';
import Blog from '../components/blog';
import PrivateRoute from './privatRoute';


function Routing() {

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="/admin1"
                element={<PrivateRoute element={<AdminPage1 />} />}
            />
            <Route
                path="/admin2"
                element={<PrivateRoute element={<AdminPage2 />} />}
            />
            <Route path="/projects/:projectId" element={<Blog />} />
        </Routes>
    );
}

export default Routing;

import React from 'react';
import phoneicon from '../assets/phone.svg'
import locationicon from '../assets/location.svg'
import emailicon from '../assets/email.svg'
import fiverricon from '../assets/fiverr1.svg'

function ContactDesign() {
    return (
        <div className="container mt-5">

            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="">
                        <div className="card-body text-justify">
                            <a href="tel:+94771617334" style={{ textDecoration: "none" }}>
                                <div className='row'>
                                    <div className="col-4  text-center">
                                        <img src={phoneicon} alt="twitter" class="icon-big" />
                                    </div>
                                    <div className="col-8 mb-4 mt-1">
                                        <p class="contact-item">+94 77 16 17 334</p>
                                    </div>
                                </div>
                            </a>

                            <a style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" href="https://www.google.com/maps?ll=8.227721,80.344368&z=14&t=m&hl=en&gl=LK&mapclient=embed&cid=13207743674241315492">
                                <div className='row'>
                                    <div className="col-4 mt-4 text-center">
                                        <img src={locationicon} alt="twitter" class="icon-big" />
                                    </div>
                                    <div className="col-8 mb-4 ">
                                        <p class="contact-item">SLMB Design Solutions,
                                            Sweet Home Rd,
                                            Talawa,
                                            SriLanka.</p>
                                    </div>
                                </div>
                            </a>

                            <a href="mailto: info@slmb.lk" style={{ textDecoration: "none" }}>
                                <div className='row'>
                                    <div className="col-4  text-center">
                                        <img src={emailicon} alt="twitter" class="icon-big" />
                                    </div>
                                    <div className="col-8 mb-4 mt-2">
                                        <p class="contact-item"> info@slmb.lk</p>
                                    </div>
                                </div>
                            </a>

                            <a href="https://www.fiverr.com/mgassoori" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }} >
                                <div className='row'>
                                    <div className="col-4 mb-4 text-center">
                                        <div className='fiverr-container'> <img src={fiverricon} alt="twitter" class="" style={{ height: "150px", marginRight: "0%" }} /> </div>
                                    </div>
                                    <div className="col-8 mb-4 mt-2">
                                        <p class="contact-item">@mgassoori</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactDesign;

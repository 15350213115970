import facebookicon from "../assets/facebook.svg"
import youtubeicon from "../assets/youtube.svg"
import instagramlicon from "../assets/instagram.svg"
import linkedinicon from "../assets/linkedin.svg"
import twittericon from "../assets/twitter.svg"
import fiverricon from "../assets/fiverr.png"


function Footer() {
    return (
        <div class="footer" style={{ background: "#112D4E", width: "100%", paddingBottom: "1%" }}>
            <div class="container" >
                <div class="row">
                    <div class="col-md-12 text-center foothead" style={{ color: "#F9F7F7" }}>
                        <div>
                            <h4>SLMB Design Solutions</h4>
                        </div>
                    </div>
                    
                    <div class="col-md-3 text-center ">
                        <div>
                            <div class="text-center foothead" style={{ color: "#F9F7F7", marginTop: "5%" }}>
                                <div>
                                  {/**  <h4 >Design Firm</h4> */} 
                                </div>
                            </div>
                            <a class="foot" href="https://maps.app.goo.gl/JHmH4m1CUVV75Q548" target="_blank" rel="noreferrer">SLMB Design Solutions,<br></br> Sweet Home Rd, <br></br> Talawa,  SriLanka.<br></br></a>

                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><img src={twittericon} alt="twitter" class="icon" /></a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><img src={facebookicon} alt="facebook" class="icon" /></a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><img src={youtubeicon} alt="youtube" class="icon" /></a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><img src={instagramlicon} alt="email" class="icon" /></a>
                            <a href="https://www.linkedin.com/company/slmb-design-solutions-pvt-ltd/" target="_blank" rel="noreferrer"><img src={linkedinicon} alt="linkedin" class="icon" /></a>
                        </div>
                        <div class="col-md-12 text-center ">
                            <a href="https://www.fiverr.com/mgassoori" target="_blank" rel="noreferrer"><img src={fiverricon} alt="fiverr" class="iconfi" /></a>
                        </div>
                    </div>
                    <div class="col-md-3 text-center" style={{ marginBottom: "2%" }}>
                        <div >
                        <div class="text-center foothead" style={{ color: "#F9F7F7", marginTop: "5%" }}>
                        <div>
                           {/** <h4>Sales Branch</h4>  */}
                        </div>
                    </div>
                           {/** <a class="foot" href="https://maps.app.goo.gl/JHmH4m1CUVV75Q548" target="_blank" rel="noreferrer">SLMB Design Solutions,<br></br>Brisbane, Australia.<br></br></a>
                            <a class="foot" href="tel:+61425701108">Tel. : +61 42 57 01 108</a> <br />
                            <a class="foot" href="mailto: sajeewa.se@slmb.lk">Email:  sajeewa.se@slmb.lk</a>  */}

                            <a class="foot" href="tel:+94771617334">Tel. : +94 77 16 17 334</a> <br />
                            <a class="foot" href="mailto: info@slmb.lk">Email:  info@slmb.lk</a>
                        </div>
                    </div>

                    <div class="col-md-12 text-center footerd">
                        <div>
                            <h7 >Copyrights © 2024 All Rights Reserved by SLMB Design Solutions.</h7>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;
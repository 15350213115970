import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Link, useParams } from 'react-router-dom';


function Blog() {

    const { projectId } = useParams(); // Get the project ID from the URL

    const [projects, setProject] = useState(null);

    useEffect(() => {
        if (projectId) {
            getProject(projectId);
        }
    }, [projectId]);

    const getProject = async (projectId) => {
        const projectRef = doc(firestore, 'projects', projectId);
        const projectDoc = await getDoc(projectRef);

        if (projectDoc.exists()) {
            setProject({ id: projectDoc.id, ...projectDoc.data() });
        } else {
            // Handle the case where the document does not exist
            console.log('Document not found');
        }
    };

    const navigateToProject = (direction) => {
        // Implement logic to get the next or previous project ID
        // For simplicity, assuming project IDs are sequential and start from 1
        const nextProjectId = direction === 'next' ? parseInt(projectId) + 1 : parseInt(projectId) - 1;
        getProject(nextProjectId.toString());
    };


    return (
        <div>
            {projects && (
                <div className="container">
                    <h3 className='mb-4 mt-5'>{projects.head}</h3>
                    <div class="row text-center">

                        <div class="col-lg-4 col-md-4 col-sm-6 ">
                            <img class="blog__item__pic large__item  mb-5" src={projects.i1} alt='project'></img>
                        </div>

                        <div class="col-lg-8 col-md-8 col-sm-6" >
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <img class="blog__item__pic " src={projects.i2} alt='project'></img>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <img class="blog__item__pic " src={projects.i3} alt='project'></img>
                                </div>
                            </div>

                            <div>
                                <p>{projects.p1}</p>

                                <p>{projects.p2}</p>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8 col-sm-6" >
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <img class="blog__item__pic m-1" src={projects.i4} alt='project'></img>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <img class="blog__item__pic m-1" src={projects.i5} alt='project'></img>
                                </div>
                            </div>

                            <div>

                                <p>{projects.p3}</p>

                                <p>{projects.p4}</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 ">
                            <img class="blog__item__pic large__item m-1" src={projects.i6} alt='project'></img>
                        </div>

                    </div>

                </div>
            )}

            <div className="m-4 text-center">
                <Link to={`/projects/${parseInt(projectId) - 1}`} onClick={() => navigateToProject('previous')}>
                    <button type="button" className="btn btn-primary m-2" disabled={parseInt(projectId) === 1} style={{ backgroundColor: '#112D4E' }}>
                        Previous Project
                    </button>
                </Link>

                <Link to={`/projects/${parseInt(projectId) + 1}`} onClick={() => navigateToProject('next')}>
                    <button type="button" className="btn btn-primary m-2" style={{ backgroundColor: '#112D4E' }}>
                        Next Project
                    </button>
                </Link>
            </div>

        </div>
    );
}

export default Blog;

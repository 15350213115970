import HomeIntro from "../components/homeIntro";
import HomeServices from "../components/homeservice";
import ReviewText from "../components/reviews";
import HowItWorks from "../components/steps";


function HomePage() {
    return (
      <div>
        <HomeIntro></HomeIntro>
        <HomeServices></HomeServices>
        <HowItWorks></HowItWorks>
        <ReviewText></ReviewText>
        
      </div>
  );
}

export default HomePage;
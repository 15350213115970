import { Link } from "react-router-dom";
import AddProject from "../components/addproject";



function AdminPage2() {
    return (
        <div class={"container"}>
            <h1 class={"text-center"}>Add Projects</h1>
            <AddProject></AddProject>

            <div className="text-center">
            <Link to="/admin1" >
                <button type="button" className="btn btn-primary">
                    Previous Page
                </button>
            </Link>
            </div>

        </div>
    );
}

export default AdminPage2;

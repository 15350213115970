import Services from "../components/services";



function SevicesPage() {
    return (


        <div>
            <h3 className="mb-4 text-center mt-4 ">OUR SERVICES</h3>
           <Services></Services>
        </div>
    );
}

export default SevicesPage;
import { useEffect, useState } from "react";
import { firestore } from "../firebase";
import { getDocs, orderBy, query, collection } from "firebase/firestore";


function Services() {

    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices();
    }, []);

    const getServices = async () => {
        const q = query(collection(firestore, 'services'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
        const querySnapshot = await getDocs(q);
        const serviceList = [];
        querySnapshot.forEach((doc) => {
            serviceList.push({ id: doc.id, ...doc.data() });
        });
        setServices(serviceList);
    };


    return (
        <div className="container" style={{ marginBottom: "3%", marginTop: "3%" }}>
            
            {services
                .sort((a, b) => a.order - b.order) // Sort services array by order property
                .map(service => (
                    <div key={service.id} id={service.id} className="row" style={{ marginBottom: "5%", marginTop: "5%" }}>
                        {service.order % 2 !== 0 ? (
                            // Odd number, display image first, then headings and descriptions
                            <>
                                <div className="col-md-12 col-lg-6 order-md-2">
                                    <div className="text-left">
                                        <h3>{service.head}</h3>
                                    </div>
                                    <div className="text-left">
                                        <p>{service.description1} <br/><br/> {service.description2} </p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 order-md-1">
                                    <img src={service.image} className="w-100" alt="" style={{ borderRadius: "25px" }} />
                                </div>

                            </>
                        ) : (
                            // Even number, display headings and descriptions first, then image
                            <>
                                <div className="col-md-12 col-lg-6 order-md-1">
                                    <div className="text-left">
                                        <h3>{service.head}</h3>
                                    </div>
                                    <div className="text-left">
                                        <p>{service.description1} <br/><br/> {service.description2} </p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 order-md-2">
                                    <img src={service.image} className="w-100" alt="" style={{ borderRadius: "25px" }} />
                                </div>
                            </>
                        )}
                    </div>
                ))}
        </div>


    );
}

export default Services;




import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../firebase";


function LoginPage() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin1');
    } catch (error) {
      console.error('Error during sign-in:', error.code);
      if (error.code === 'auth/user-not-found') {
        alert('User not found. Please check your email and try again.');
      } else if (error.code === 'auth/wrong-password') {
        alert('Invalid password. Please try again.');
      } else if (error.code === 'auth/invalid-email') {
        alert('Invalid email address. Please check your email and try again.');
      } else {
        alert('An error occurred. Please try again later.');
      }
    }
    
    
  };

  return (
    <div>

      <div class="container py-5 h-80">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card text-white" style={{ backgroundColor: "#112D4E" }} >
              <div class="card-body p-5 text-center">

                <div class="">

                  <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                  <p class="text-white-50 mb-5">Please enter your Email and password!</p>

                  <form onSubmit={handleLogin}>
                    <div className="form-outline form-white mb-4">
                      <input
                        type="email"
                        id="typeEmailX"
                        className="form-control form-control-lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label className="form-label" htmlFor="typeEmailX">
                        Email
                      </label>
                    </div>
                    <div className="form-outline form-white mb-4">
                      <input
                        type="password"
                        id="typePasswordX"
                        className="form-control form-control-lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label className="form-label" htmlFor="typePasswordX">
                        Password
                      </label>
                    </div>
                    <button className="btn btn-outline-light btn-lg px-5" type="submit">
                      Login
                    </button>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default LoginPage;
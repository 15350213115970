// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBH6GVjOkNlINU8y4_q28hknkCnbzUUAM0",
  authDomain: "slmb-web.firebaseapp.com",
  projectId: "slmb-web",
  storageBucket: "slmb-web.appspot.com",
  messagingSenderId: "857805693329",
  appId: "1:857805693329:web:4f5bdf94cd399830247a30",
  measurementId: "G-L55BKTRKEE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const auth = getAuth(app);

export { auth, signInWithEmailAndPassword };


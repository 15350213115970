import logo from "../assets/logoonly.PNG"


function NavBar() {
  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-md navigation-home">
        <div className="container">
          <a className="navbar-brand" href="/">
            <div className="d-flex align-items-center">
              <img src={logo} alt="Logo" width="75" height="60" className="mr-2" style={{ fontSize: "40px" }} />
              <h3 className="m-0 mr-2 ">SLMB&nbsp;</h3>
              <h3 className="m-0 mr-2 logoname text-wrap ">Design Solutions</h3>
            </div>
          </a>



          <button
            className="navbar-toggler collapsed position-absolute top-0 end-0 mt-3 me-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#myNavbar"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item navi">
                <a className="nav-link home-link" href="/about">
                  About
                </a>
              </li>
              
              <li className="nav-item navi">
                <a className="nav-link home-link" href="/services">
                  Services
                </a>
              </li>
              <li className="nav-item navi">
                <a className="nav-link home-link" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

  );
}






export default NavBar;

import { Navigate } from 'react-router-dom';
import { auth } from '../firebase';

const PrivateRoute = ({ element }) => {
  const { currentUser } = auth; // Assuming 'currentUser' is a property you want to check for authentication

  return currentUser ? (
    element
  ) : (
    <Navigate to="/login" replace state={{ from: window.location.pathname }} />
  );
};

export default PrivateRoute;

import React from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import faceimg from '../assets/logoonly.PNG'

/**
 * Repo: https://github.com/awran5/react-floating-whatsapp
 */

export default function WhatsApp() {
  return (
    <div className="WhatsApp">
      <FloatingWhatsApp
        phoneNumber="+94771617334"
        accountName="Customer Support"
        allowEsc
        allowClickAway
        notificationDelay={false}
        notificationSound
        avatar={faceimg}
        chatMessage="Hello there! How can I help you?"
        
        
      />
    </div>
  );
}

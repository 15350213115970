import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const contents = [
  {
    title: "We discuss your design requirements or review your documented statement, ensuring confidentiality and signing NDA.",
  },
  {
    title: "We translate your requirements into clear, precise engineering specifications for our design process.",
  },
  {
    title: "We create innovative conceptual designs as the blueprint for your project.",
  },
  {
    title: "We develop initial 3D engineering plans from your conceptual ideas.",
  },
  {
    title: "We present initial designs for your feedback to ensure they meet expectations.",
  },
  {
    title: "We refine initial designs into detailed, precise plans ready for prototyping.",
  },
  {
    title: "We seek your final approval on detailed designs before proceeding.",
  },
  {
    title: "We deliver your final product with 2D drawings and 3D models, meeting quality standards.",
  },
];

const ContentItem = ({ item, index }) => (
  <div className="h-100 px-3">
    <h2 className="ezy__howitworks7-count">
      {index < 10 ? `0${index}` : index}.
    </h2>
    <p className="ezy__howitworks7-title">{item.title}</p>
  </div>
);

ContentItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const HowItWorks = () => {
  return (
    <section className="pb-4 mt-4" style={{backgroundColor:"#bbb"}}>
      <Container >
        <Row>
          <Col xs={12} md={12}>
            <h2 className="mt-4 foothead text-center" style={{paddingTop:"20px"}}>
              Our Design Procedure
            </h2>
          </Col>
        </Row>

        <div className="" />

        <Row>
          {contents.map((item, i) => (
            <Col xs={12} md={4} lg={3} className="mt-md-5" key={i}>
              <ContentItem index={i + 1} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};


export default HowItWorks;
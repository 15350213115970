import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, doc, setDoc, addDoc, orderBy, query, deleteDoc } from "firebase/firestore";

const AddProject = () => {

  const [i1, setI1] = useState(null);
  const [i2, setI2] = useState(null);
  const [i3, setI3] = useState(null);
  const [i4, setI4] = useState(null);
  const [i5, setI5] = useState(null);
  const [i6, setI6] = useState(null);
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const [p3, setP3] = useState('');
  const [p4, setP4] = useState('');
  const [order, setOrder] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [footer, setFooter] = useState('');
  const [head, setHead] = useState('');
  const [projects, setProjects] = useState([]);



  useEffect(() => {
    getProjects();
  }, []);

  const addProject = async () => {
    try {
      const docRef = await addDoc(collection(firestore, "projects"), {

        image,
        head,
        description,
        footer,
        order,
        i1,
        i2,
        i3,
        i4,
        i5,
        i6,
        p1,
        p2,
        p3,
        p4

      });
      console.log("Document successfully written with ID: ", docRef.id);
      alert("Project Successfully Added !");
      clearTexts();
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Project Adding Failed !");
    }
  }

  const deleteProject = async (serviceId) => {
    try {
      await deleteDoc(doc(firestore, "projects", serviceId));
      console.log("Document successfully deleted!");
      alert("Project Deleted !");
      await getProjects(); // Wait for the deletion to complete before fetching updated data
    } catch (error) {
      console.error("Error deleting document: ", error);
      alert("Delete Failed !");
    }
  }



  const updateProject = async (projectId) => {
    try {
      const projectRef = doc(firestore, "projects", projectId);
      await setDoc(projectRef, {

        order,
        image,
        head,
        description,
        footer,
        i1,
        i2,
        i3,
        i4,
        i5,
        i6,
        p1,
        p2,
        p3,
        p4

      }, { merge: true });
      console.log("Document successfully updated!");
      alert("Project Successfully Updated !");
      clearTexts();
      // Optionally, you can refresh the reviews after update
      getProjects();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Project Update Failed !");
    }
  }



  const getProjects = async () => {
    const q = query(collection(firestore, 'projects'), orderBy('order', 'asc')); // Replace 'fieldName' with the field you want to sort by
    const querySnapshot = await getDocs(q);
    const projectList = [];
    querySnapshot.forEach((doc) => {
      projectList.push({ id: doc.id, ...doc.data() });
    });
    setProjects(projectList);
  };


  const handleI1 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI1(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI1(null);
    }
  };
  const handleI2 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI2(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI2(null);
    }
  };
  const handleI3 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI3(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI3(null);
    }
  };
  const handleI4 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI4(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI4(null);
    }
  };
  const handleI5 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI5(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI5(null);
    }
  };
  const handleI6 = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setI6(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setI6(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here (e.g., sending data to the server)
    console.log('Form submitted:', { i1, i2, i3, i4, i5, i6, p1, p2, p3, p4, image, description, footer, head, order });
  };

  const clearTexts = () => {

    setI1('');
    setI2('');
    setI3('');
    setI4('');
    setI5('');
    setI6('');
    setP1('');
    setP1(''); setP2(''); setP3(''); setP4('');
    setImage('');
    setHead('');
    setDescription('');
    setFooter('');
    setOrder('');

  }


  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImage(null);
    }
  };




  return (

    <div>
      <div className='container '>
        <div className="container mt-5">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">


                <form onSubmit={handleSubmit}>

                  <div className='row' >

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Order</label>
                        <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="01" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="image" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>

                      {image && (
                        <div className="mb-3">
                          <label className="form-label">Image Preview</label>
                          <img src={image} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}

                      <div className="mb-3">
                        <label htmlFor="head" className="form-label">
                          Project Head
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="head"
                          value={head}
                          onChange={(e) => setHead(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          rows="4"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="footer" className="form-label">
                          Footer Text
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="footer"
                          value={footer}
                          onChange={(e) => setFooter(e.target.value)}
                          rows="1"
                        />
                      </div>

                      <label className="form-label ">
                        Portfolio Pages
                      </label>

                      <div className="mb-3">
                        <label htmlFor="description1" className="form-label">
                          Description Para1
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description1"
                          value={p1}
                          onChange={(e) => setP1(e.target.value)}
                          rows="4"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description2" className="form-label">
                          Description Para2
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description2"
                          value={p2}
                          onChange={(e) => setP2(e.target.value)}
                          rows="4"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description3" className="form-label">
                          Description Para3
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description3"
                          value={p3}
                          onChange={(e) => setP3(e.target.value)}
                          rows="4"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description4" className="form-label">
                          Description Para4
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description4"
                          value={p4}
                          onChange={(e) => setP4(e.target.value)}
                          rows="4"
                        />
                      </div>
                    </div>




                    <div class="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="image1" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image1"
                          accept="image1/*"
                          onChange={handleI1}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image2" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image2"
                          accept="image2/*"
                          onChange={handleI2}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image3" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image3"
                          accept="image3/*"
                          onChange={handleI3}
                        />
                      </div>
                      {i1 && (
                        <div className="mb-3">
                          <label className="form-label">Image1 Preview</label>
                          <img src={i1} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}
                      {i2 && (
                        <div className="mb-3">
                          <label className="form-label">Image2 Preview</label>
                          <img src={i2} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}
                      {i3 && (
                        <div className="mb-3">
                          <label className="form-label">Image3 Preview</label>
                          <img src={i3} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}

                      <div className="mb-3">
                        <label htmlFor="image4" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image4"
                          accept="image4/*"
                          onChange={handleI4}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image5" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image5"
                          accept="image5/*"
                          onChange={handleI5}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image6" className="form-label">
                          Upload Image 1200px x 630px 72ppi
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image6"
                          accept="image6/*"
                          onChange={handleI6}
                        />
                      </div>

                      {i4 && (
                        <div className="mb-3">
                          <label className="form-label">Image4 Preview</label>
                          <img src={i4} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}
                      {i5 && (
                        <div className="mb-3">
                          <label className="form-label">Image5 Preview</label>
                          <img src={i5} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}
                      {i6 && (
                        <div className="mb-3">
                          <label className="form-label">Image6 Preview</label>
                          <img src={i6} alt="Preview" className="img-thumbnail" style={{ width: '200px' }} />
                        </div>
                      )}


                      <button type="submit" className="btn btn-primary" onClick={addProject}>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Order</th>
                  <th scope="col">Image</th>
                  <th scope="col">Head</th>
                  <th scope="col">Description</th>
                  <th scope="col">Portfolio Images</th>
                  <th scope="col">Paragraphs</th>
                </tr>
              </thead>
              <tbody>
                {projects.map(projects => (
                  <tr key={projects.id}>

                    <td>{projects.order}</td>
                    <td>{projects.image && <img src={projects.image} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                    <td>{projects.head}</td>
                    <td>{projects.description} <br /><br />{projects.footer}</td>
                    <td>{projects.i1 && <img src={projects.i1} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}<br />
                      {projects.i2 && <img src={projects.i2} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}<br />
                      {projects.i3 && <img src={projects.i3} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}<br />
                      {projects.i4 && <img src={projects.i4} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}<br />
                      {projects.i5 && <img src={projects.i5} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}<br />
                      {projects.i6 && <img src={projects.i6} alt="Service" style={{ maxWidth: '75px', height: 'auto' }} />}</td>
                    <td>{projects.p1} <br /><br />{projects.p2}<br /><br />{projects.p3}<br /><br />{projects.p4}</td>

                    <td className='text-center'>
                      <button
                        className="btn btn-success"
                        onClick={() => updateProject(projects.id)}
                      >
                        Update
                      </button>

                      <button
                        className="btn btn-primary m-1"
                        onClick={() => {
                          setOrder(projects.order);
                          setImage(projects.image || null);
                          setHead(projects.head);
                          setDescription(projects.description);
                          setFooter(projects.footer);
                          setI1(projects.i1 || null);
                          setI2(projects.i2 || null);
                          setI3(projects.i3 || null);
                          setI4(projects.i4 || null);
                          setI5(projects.i5 || null);
                          setI6(projects.i6 || null);
                          setP1(projects.p1); setP2(projects.p2); setP3(projects.p3); setP4(projects.p4);

                        }}
                      >
                        Load
                      </button>

                      <button type="button" class="btn btn-danger " onClick={() => deleteProject(projects.id)}>Delete</button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AddProject;

import about1 from "../assets/aboutus.webp"


function AboutPage() {
    return (
        <div class="about">
            <div class="container" style={{ marginTop: "0%", paddingBottom: '3%' }} >
                <div class="row">
                    <div class="col-md-12 " style={{ width: '100', overflow: 'hidden' }}>
                        <img src={about1} className="d-block w-100" style={{ objectFit: 'cover', width: '100%', height: '100%' }} alt="..." />
                    </div>

                    <div class="col-md-4 text-left about">
                        <div style={{ textAlign: "justify" }}>
                            <h4 className="foothead" style={{ marginBottom: "7%" }}><strong>Who We Are</strong></h4>
                            <h7 >“SLMB Design Solutions (PVT) LTD is a top mechanical engineering design firm in Sri Lanka. We excel in delivering comprehensive design and prototyping services to clients around the world."</h7>
                        </div>
                    </div>
                    <div class="col-md-4 text-left about">
                        <div style={{ textAlign: "justify" }}>
                            <h4 className="foothead" style={{ marginBottom: "7%" }}><strong>Our Mission</strong></h4>
                            <h7>"Our mission is to drive innovation and excellence in mechanical design. We guide clients from ideas to finished products, using our expertise and creativity to shape the future of technology and industry."</h7>
                        </div>
                    </div>
                    <div class="col-md-4 text-left about">
                        <div style={{ textAlign: "justify" }}>
                            <h4 className="foothead" style={{ marginBottom: "7%" }}><strong>Our Vision</strong></h4>
                            <h7>"To be a global leader in mechanical engineering design and prototyping, known for our commitment to quality and innovation."</h7>
                        </div>
                    </div>

                    <div className="col-md-12 text-left about">
                        <div style={{ textAlign: "center" }}>
                            <h4 className="foothead" style={{ marginBottom: "2%" }}><strong>Why Choose Us</strong></h4>
                            <p style={{ lineHeight: "2.5", textAlign: "center", fontSize: "20px"}} >
                                <strong>“Expertise:</strong> Our engineers have vast experience in mechanical design and prototyping.“
                                <br /><strong>“Quality:</strong> We deliver high-quality solutions that meet top standards.“
                                <br /><strong>“Innovation:</strong> We use the latest technologies and techniques to create innovative solutions.“
                                <br /><strong>“Global Reach:</strong> We have completed projects for clients in many countries.“
                                <br /><strong>“Customer Satisfaction:</strong> Our clients’ satisfaction is our priority. We work closely to meet your needs.”
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default AboutPage;



